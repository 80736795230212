import { Business } from "@/types/BusinessInterface";
import { LotInterface } from "@/types/LotInterface";

export function getBusinessNameFromLot(
  lot: LotInterface,
  businesses: Business[]
): string {
  const matchedBusiness = businesses.find(
    (business) =>
      business.landownerId &&
      lot.ownershipId &&
      business.landownerId === lot.ownershipId &&
      business.estateIds.includes(lot.estateId?._id)
  );

  return matchedBusiness?.name ?? "-";
}
